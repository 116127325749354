import React from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "../../components/DoDoswiaczenie/HeaderDos";
import TextDos from "../../components/DoDoswiaczenie/TextDos";
import Baner from "../../components/DoDoswiaczenie/BanerDos";

import Footer from "../../components/Footer";
import { ScrollToTop } from "../../components";

const Doswiaczenie = () => {
  return (
    <>
      <div className='dos_all' id='dos_top'>
        <Header />
        <Baner />
        <TextDos />

        <Footer />
      </div>
      <ScrollToTop />
    </>
  );
};

export default Doswiaczenie;
