import React from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiOutlinePhone, AiOutlineHome, AiOutlineSkype } from "react-icons/ai";
import { SiMicrosoftteams } from "react-icons/si";

export default function OfertaExtendPsych() {
  return (
    <>
      <div className='psych_gora' id='psych_doofer_TO1'></div>
      <div className='container'>
        <div className='row'>
          <div className='col psych_zdalne'>
            <div className='col psych_pogrubienie'>Psychoterapia zdalna:</div>
            <div className='row psych_apps'>
              Skype <AiOutlineSkype size={100} />
            </div>
            <div className='row psych_apps'>
              MSTeams <SiMicrosoftteams size={100} />
            </div>
            <div className='row psych_apps'>
              Telefon <AiOutlinePhone size={100} />
            </div>
          </div>

          <div className='col psych_stacjonarne'>
            <div className='col psych_pogrubienie'>
              Psychoterapia stacjonarna:
            </div>
            <div className='row psych_apps'>
              Gabinet <AiOutlineHome size={100} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
