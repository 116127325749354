import { React, useState, useEffect } from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { HiLocationMarker, HiPhone } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";

export const classNames = (...classes) => {
  return classes.filter(Boolean);
};

export default function HeaderPsych() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);

  const toggleVisible = () => {
    if (window.pageYOffset === 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);

    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  });

  // function to go
  // const goto = (x) => {
  //   document.getElementById(x).scrollIntoView();
  // };

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onlogo = (x) => {
    if (window.location.pathname !== `/`) {
      navigate(`/`);
      return new Promise((res) => {
        res("1");
      });
    } else {
      ScrollToTop();
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="xxl"
      className={classNames(isVisible ? "kons_nav" : "kons_nav_scrolled")}
    >
      <div className="container-fluid">
        <Navbar.Brand
          className="kons_logo"
          onClick={(e) => {
            onlogo();
          }}
        >
          Auxilium
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={(e) => navigate("/")} className="kons_nav_links">
              O mnie
            </Nav.Link>
            <Nav.Link onClick={(e) => navigate("/")} className="kons_nav_links">
              Kontakt
            </Nav.Link>
            <Nav.Link
              onClick={(e) => navigate("/doswiadczenie")}
              className="kons_nav_links"
            >
              Doświadczenie
            </Nav.Link>
            <Nav.Link
              onClick={(e) => navigate("/psychoterapia")}
              className="kons_nav_links"
            >
              Psychoterapia
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>

      <Nav className="kons_info__nav">
        <Navbar.Text className="kons_info__email">
          <MdEmail />
          katarzyna.m.stefanska@gmail.com
        </Navbar.Text>
        <Navbar.Text className="kons_info__adres">
          <HiLocationMarker />
          Warszawa, Racławicka 30
        </Navbar.Text>
        <Navbar.Text className="kons_info__telefon">
          <HiPhone />
          518 670 458
        </Navbar.Text>
      </Nav>
    </Navbar>
  );
}
