import React from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

export default function BanerPsych() {
  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='psych_baner'>
            <div className='psych_text1'>PSYCHOTERAPIA</div>
          </div>
        </div>
      </div>
    </>
  );
}
