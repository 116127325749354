import React from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

export default function BanerPsych() {
  return (
    <>
      {/* <div className='dos_gora'></div> */}
      <div className='container-fluid'>
        <div className='row'>
          <div className='dos_baner'>
            <div className='dos_text1'>DOŚWIADCZENIE</div>
          </div>
        </div>
      </div>
    </>
  );
}
