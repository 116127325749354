import React from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import obraz1 from "../../assets/oferta_kons1.png";
import obraz2 from "../../assets/oferta_kons3.png";

export default function Oferta() {
  const navigate = useNavigate();

  // const goto = (x) => {
  //   document.getElementById(x).scrollIntoView();
  // };

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const navigateTo = (x) => {
    if (window.location.pathname !== `/${x}`) {
      navigate(`/${x}`);

      return new Promise((res) => {
        res("1");
      });
    }
  };

  return (
    <>
      <div className="margin" id="oferta"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="ofertatext">Oferta pomocy</div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col m-5" align="center">
            <div
              className="dla_par"
              onClick={(e) => {
                navigateTo("konsultacje");
                ScrollToTop();
              }}
            >
              <div className="oferta_text_kons">Konsultacje</div>
              <hr></hr>
              <div className="oferta_text">
                {/* Stacjonarnie <br></br>
                Zdalnie - Gabinet, Skype, MSTeams, WhatsApp, telefonicznie*/}
                <img
                  className="img rounded oferta_obraz"
                  src={obraz1}
                  height="auto"
                  width="100%"
                  alt="..."
                />
              </div>
              <div className="cena">
                <hr></hr>
                <div
                  className="oferta_wiecej"
                  onClick={(e) => {
                    navigateTo("konsultacje");
                    ScrollToTop();
                  }}
                >
                  Więcej
                </div>
              </div>
            </div>
          </div>
          <div className="col m-5" align="center">
            <div
              className="dla_par"
              onClick={(e) => {
                navigateTo("psychoterapia");
                ScrollToTop();
              }}
            >
              Psychoterapia
              <hr></hr>
              <div className="text">
                {/* Stacjonarnie <br></br>
                Zdalnie - Gabinet, Skype, MSTeams, telefonicznie */}

                <img
                  className="img rounded"
                  src={obraz2}
                  height="auto"
                  width="100%"
                  alt="..."
                />
              </div>
              <div className="cena">
                <hr></hr>
                <div
                  className="oferta_wiecej"
                  onClick={(e) => {
                    navigateTo("psychoterapia");
                    ScrollToTop();
                  }}
                >
                  Więcej
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
