import React from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { HiLocationMarker, HiPhone } from "react-icons/hi";
import { MdEmail } from "react-icons/md";

export default function Kontakt() {
  return (
    <>
      <div className="kontakt_margin"></div>
      <div className="container-fluid" id="kontakt">
        <div className="kontakt_all">
          <div className="row">
            <div className="col" align="center">
              <HiPhone size={100} />
              <div className="kontakt_maly_tel">Tel: 518 670 458</div>
            </div>
            <div className="col" align="center">
              <HiLocationMarker size={100} />
              <div className="kontakt_maly_adres">Warszawa, Racławicka 30</div>
            </div>
            <div className="col" align="center">
              <MdEmail size={100} />
              <div className="kontakt_maly_email">
                katarzyna.m.stefanska@gmail.com
              </div>
            </div>
            {/* <div className='contaneree1-box'>
              <div className='right'>
                <h2>Kontakt</h2>

                <form>
                  <input
                    type='text'
                    className='field'
                    placeholder='Imię'
                    name='imie'
                    id='imie'
                    required
                  />
                  <input
                    type='text'
                    className='field'
                    placeholder='Twój adres email'
                    name='email'
                    id='email'
                    required
                  />
                  <input
                    type='text'
                    className='field'
                    placeholder='Telefon (nie jest wymagany)'
                    id='telefon'
                    name='telefon'
                  />
                  <textarea
                    placeholder='...'
                    className='field'
                    name='text'
                    id='text'
                    required
                    maxLength='300'
                  ></textarea>
                  <input
                    className='contact-send-button'
                    type='submit'
                    value='Wyślij'
                    name='submit'
                  />
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
