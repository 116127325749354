import { useEffect, useState } from "react";
import { BiArrowFromBottom } from 'react-icons/bi';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
  
export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
  };


export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);


  
  const toggleVisible = () => {
      if(window.pageYOffset > 300) {
        setIsVisible(true);
      }else{
        setIsVisible(false);
      }
      
  };


  const ScrollToTop = () => {
    window.scrollTo({
      top:0,
      behavior: 'smooth'
    })
  };


  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);

    return () => {
      window.removeEventListener('scroll', toggleVisible);
    }
  }, []);



  return(
    <button
        type="button"
        className={classNames(
          isVisible ? 'mystyle' : 'mystyle_scrolled',
          'mystyle'
        )}
        onClick={ScrollToTop}>
        <BiArrowFromBottom />
    </button>

  )
};