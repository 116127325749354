import { React } from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { HiLocationMarker, HiPhone } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";

// export const classNames = (...classes) => {
//   return classes.filter(Boolean);
// };

export default function Header() {
  const navigate = useNavigate();

  // const [isVisible, setIsVisible] = useState(false);

  // const toggleVisible = () => {
  //   if (window.pageYOffset === 0 || window.pageYOffset > 300) {
  //     setIsVisible(false);
  //   } else {
  //     setIsVisible(true);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", toggleVisible);
  //   // kons_id_var.addEventListener("click", goto("oferta"));
  //   return () => {
  //     window.removeEventListener("scroll", toggleVisible);
  //     // kons_id_var.addEventListener("click", goto("oferta"));
  //   };
  // });

  // function to go
  const goto = (x) => {
    document.getElementById(x).scrollIntoView();
  };

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onlogo = (x) => {
    if (window.location.pathname === `/`) {
      navigate(`/`);
      ScrollToTop();
      return new Promise((res) => {
        res("1");
      });
    }
  };

  // const navigateTo = (x) => {
  //   if (window.location.pathname !== `/${x}`) {
  //     navigate(`/${x}`);
  //     return new Promise((res) => {
  //       res("1");
  //     });
  //   }
  // };

  return (
    <Navbar collapseOnSelect bg="light" expand="xl" className="nav">
      <div className="container-fluid">
        <Navbar.Brand
          className="logo"
          onClick={(e) => {
            onlogo();
          }}
        >
          Auxilium
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={(e) => goto("o___mnie")} className="nav_links">
              O mnie
            </Nav.Link>
            <Nav.Link onClick={(e) => goto("kontakt")} className="nav_links">
              Kontakt
            </Nav.Link>
            {/* <Nav.Link onClick={(e) => goto("top")} className='nav_links'>
              Gabinet
            </Nav.Link> */}

            <NavDropdown
              className="nav_links"
              title="Oferta"
              id="basic-nav-dropdown"
              aria-expanded="false"
            >
              <NavDropdown.Item className="nav_konsultacje" href="#oferta">
                Konsultacje
              </NavDropdown.Item>
              <NavDropdown.Item className="nav_psychoterapia" href="#oferta">
                Psychoterapia
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </div>
      {/* <Navbar.Toggle aria-controls="info__kont"/> */}
      <Nav className="info__nav">
        {/* <Nav.Item className='nav_links'><MdEmail /> xyz@gmail.com</Nav.Item>
                    <Nav.Item className='nav_links'><HiLocationMarker /> Plac Bankowy 123123123 sa dsad 23121231</Nav.Item>
                    <Nav.Item className='nav_links'><HiPhone /> 123 456 789</Nav.Item> */}

        <Navbar.Text className="info__email">
          <MdEmail />
          katarzyna.m.stefanska@gmail.com
        </Navbar.Text>
        <Navbar.Text className="info__adres">
          <HiLocationMarker />
          Warszawa, Racławicka 30
        </Navbar.Text>
        <Navbar.Text className="info__telefon">
          <HiPhone />
          518 670 458
        </Navbar.Text>
      </Nav>
    </Navbar>
  );
}
