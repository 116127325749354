import React from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "../../components/Header";
import Cytat from "../../components/Cytat";
import Info from "../../components/Info";
import Oferta from "../../components/Oferta";
import Kontakt from "../../components/Kontakt";
// import Kontakbox from "../../components/Kontaktbox";
import Footer from "../../components/Footer";
// import Hr from "../../components/Hr";
import { ScrollToTop } from "../../components";
import TextOmnie from "../../components/TextOmnie";

const Home = () => {
  return (
    <>
      <div className='all' id='top'>
        <Header />
        <Cytat />
        <Info />
        <TextOmnie />
        <Oferta />
        <Kontakt />
        {/* <Kontakbox /> */}
        <Footer />
      </div>
      <ScrollToTop />
    </>
  );
};

export default Home;
