import React from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

export default function TextDos() {
  return (
    <>
      {/* <div className='dos_gora'></div> */}
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <div className='dos_text_big mt-4'>
              Pracowałam i współpracowałam:
            </div>
            <div className='dos_text_small'>
              <div>
                <ol style={{ listStyleType: "disc" }}>
                  <li>Uniwersytecki Szpital Kliniczny w Krakowie</li>
                  <li>Ośrodek Profilaktyki Środowiskowej w Krakowie</li>
                  <li>Centrum Praw Kobiet w Warszawie </li>
                  <li>Psychiatria środowiskowa w Warszawie</li>
                  <li>
                    Środowiskowy Dom Samopomocy dla osób chorych psychicznie w
                    Warszawie
                  </li>
                  <li>Centrum Psychologii i Psychoterapii PRO PSYCHE</li>
                  <li>Centrum Medyczne Enel-med</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
