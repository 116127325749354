import AppRoutes from './routes';
import './App.css';

function App() {
  return (
    <div className="app">
    <AppRoutes/>
  </div>
  );
}

export default App;
