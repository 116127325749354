import React from "react";
import "./index.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import Profilowe from "../../assets/Stefanska_K.jpg";
import { useNavigate } from "react-router-dom";

export default function TextOmnie() {
  const navigate = useNavigate();

  const navigateTo = (x) => {
    if (window.location.pathname !== `/${x}`) {
      navigate(`/${x}`);

      return new Promise((res) => {
        res("1");
      });
    }
  };

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className='maring'></div>
      <div className='text__and__foto'>
        <div className='container'>
          <div className='row'>
            <div className='col my-4'>
              <div className='sam__img'>
                <div className='zdjecie'>
                  <img
                    className='img-fluid rounded omnie_img'
                    src={Profilowe}
                    height='auto'
                    width={"auto"}
                    alt='...'
                  />
                </div>
              </div>
            </div>
            <div className='col-lg-8 my-4'>
              <div className='opis'>
                <div className='big__text'>O Mnie</div>
                <div className='small__text'>
                  Jestem absolwentką psychologii na Uniwersytecie Jagiellońskim.
                  Odbyłam całościowe szkolenie przygotowujące do uzyskania
                  certyfikatu psychoterapeuty zgodnie z wymogami Komisji
                  Certyfikatów Psychoterapii, rekomendowanego przez Polskie
                  Towarzystwo Psychologiczne. Posiadam certyfikat coacha ICC
                  oraz studiowałam podyplomowo poszerzając wiedzę z zakresu
                  zarządzania zasobami ludzkimi na Uniwersytecie Wrocławskim.
                  Prowadzę psychoterapię w orientacji psychodynamicznej. Swoją
                  pracę poddaję regularnej superwizji, głównie u superwizorów
                  analitycznych. Doświadczenie i wiedzę zdobywałam pracując w
                  jednostkach publicznych i prywatnych, a także organizacjach
                  pozarządowych prowadząc terapię indywidualną i grupową.
                  Zajmowałam się również diagnozą i rozwojem kompetencji
                  zawodowych (głównie menedżerskich) oraz prowadziłam projekty
                  związane z zarządzaniem zasobami ludzkimi.
                  <div
                    className='textomnie_wiecej'
                    onClick={(e) => {
                      navigateTo("doswiadczenie");
                      ScrollToTop();
                    }}
                  >
                    Moje doświadczenie
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='xxx'>
        <Carousel>
          <CarouselItem>Item 1</CarouselItem>
          <CarouselItem>Item 2</CarouselItem>
          <CarouselItem>Item 3</CarouselItem>
        </Carousel>
      </div> */}
    </>
  );
}
