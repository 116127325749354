import React from "react";
import "./index.css";

export default function Footer() {
  return (
    <footer>
      <a href="#cont">
        <ion-icon name="call"></ion-icon>
      </a>
      <div className="textsizeinfooter">
        Katarzyna Stefańska Psycholog Warszawa <br></br> &copy;2023 by{" "}
        <a href="https://maciekk.me/">Maciekk</a>
      </div>
    </footer>
  );
}
