import React from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  AiOutlineWhatsApp,
  AiOutlinePhone,
  AiOutlineHome,
  AiOutlineSkype,
} from "react-icons/ai";
import { SiMicrosoftteams } from "react-icons/si";

export default function OfertaExtendKons() {
  return (
    <>
      <div className='gora' id='doofer_TO1'></div>
      <div className='container'>
        <div className='row'>
          <div className='col kons_zdalne'>
            <div className='col kons_pogrubienie'>Konsultacje zdalne:</div>
            <div className='row kons_apps'>
              Skype <AiOutlineSkype size={100} />
            </div>
            <div className='row kons_apps'>
              MSTeams <SiMicrosoftteams size={100} />
            </div>
            <div className='row kons_apps'>
              WhatsApp <AiOutlineWhatsApp size={100} />
            </div>
            <div className='row kons_apps'>
              Telefon <AiOutlinePhone size={100} />
            </div>
          </div>

          <div className='col kons_stacjonarne'>
            <div className='col kons_pogrubienie'>Konsultacje stacjonarne:</div>
            <div className='row kons_apps'>
              Gabinet <AiOutlineHome size={100} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
