import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/HomePage";
// import Omnie from './pages/OmniePage';
import KonsultacjePage from "./pages/KonsultacjePage";
import PsychoterapiaPage from "./pages/PsychoterapiaPage";
import DoswiadczeniePage from "./pages/DoswiadczeniePage";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/konsultacje' element={<KonsultacjePage />} />
        <Route exact path='/psychoterapia' element={<PsychoterapiaPage />} />
        <Route exact path='/doswiadczenie' element={<DoswiadczeniePage />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
