import React from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "../../components/DoOfertaPsych/HeaderPsych";
import OfertaExtend from "../../components/DoOfertaPsych/OfertaExtendPsych";
import Baner from "../../components/DoOfertaPsych/BanerPsych";
//import Navadd from "../../components/DoOfertaPsych/NavaddPsych";
import Footer from "../../components/Footer";
import { ScrollToTop } from "../../components";

const Psychoterapia = () => {
  return (
    <>
      <div className='psych_all' id='psych_top'>
        <Baner />
        <Header />
        {/* <Navadd /> */}
        <OfertaExtend />

        <Footer />
        <ScrollToTop />
      </div>
    </>
  );
};

export default Psychoterapia;
