import React from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Info() {
  return (
    <div className='container-fluid'>
      <div className='info_all'>
        <div className='row'>
          <div className='col'>
            <div className='terapia_text'>Kilka słów o terapii</div>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='terapia_text_ins'>
              Psychoterapia to rozmowa o dość szczególnym charakterze. Nie
              przypomina towarzyskiej pogawędki ani dokładnych zaleceń
              podawanych przez specjalistę, nie jest też dialogiem z
              nauczycielem, mentorem czy doradcą duchowym. Pacjenci często nie
              mają żadnych wcześniejszych doświadczeń w tym zakresie, więc nie
              mogą jej z niczym porównać. Ponadto, jeśli szukając pomocy, czują
              się podatni na zranienia, odsłonięci i drażliwi, mają skłonność do
              błędnego rozumienia starań terapeuty.
            </div>
            <div className='row mx-4 info_autor'>~ Nancy McWilliams</div>
            <div id='o___mnie'></div>
          </div>
        </div>
      </div>
    </div>
  );
}
