import React from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "../../components/DoOfertaKons/HeaderKons";
import OfertaExtend from "../../components/DoOfertaKons/OfertaExtendKons";
import Baner from "../../components/DoOfertaKons/BanerKons";
//import Navadd from "../../components/DoOfertaKons/NavaddKons";

import Footer from "../../components/Footer";
import { ScrollToTop } from "../../components";

const Konsultacje = () => {
  return (
    <>
      <div className='kons_all' id='top'>
        <Header />
        <Baner />
        {/* <Navadd /> */}
        <OfertaExtend />

        <Footer />
      </div>
      <ScrollToTop />
    </>
  );
};

export default Konsultacje;
